@import './_fonts';

//NAVBAR CUSTOMIZATION
.navbar.shadow-sm {
  box-shadow: 0 -2px 7px 0 rgb(0 0 0 / 80%) !important;
}

.navbar .navbar-nav .nav-item {
  position: relative;
}



.nav-item::after {
  position: absolute;
  bottom: -21px;
  left: 0;
  right: 0;
  margin: auto;
  background-color: map-get($theme-colors, 'primary');
  width: 0;
  content: '';
  height: 4px;
}

@media (max-width: 767px) {
  .nav-item::after {
    visibility: hidden;
  }

  .nav-item.active {
    font-weight: bold;
  }
}

.navbar .navbar-nav .nav-item:hover::after {
  width: 100%;
}

.navbar .navbar-nav .nav-item .nav-link:hover {
  color: map-get($theme-colors, 'primary') !important;
}

.navbar .navbar-nav .nav-item.active::after {
  width: 100% !important;
}

.navbar .navbar-nav .nav-item.active > a {
  color: map-get($theme-colors, 'primary') !important;
}

.navbar {
  background: rgba(255, 255, 255, 0.95);
}

.container-fluid-boxed {
  @extend .container-fluid;
  max-width: 1400px;
}

.mat-mdc-header-cell {
  color: map-get($theme-colors, 'primary');
}

.mat-mdc-cell {
  vertical-align: middle !important;
}


.btn-light-no-border:not(:hover) {
  background: transparent !important;
  border-color: transparent !important
}

.line-clamp,
.line-clamp-1 { overflow:hidden; display:-webkit-box; -webkit-box-orient:vertical; -webkit-line-clamp:1; }
.line-clamp-2 { overflow:hidden; display:-webkit-box; -webkit-box-orient:vertical; -webkit-line-clamp:2; }
.line-clamp-3 { overflow:hidden; display:-webkit-box; -webkit-box-orient:vertical; -webkit-line-clamp:3; }


// GLOBAL STYLES FOR THE REPORT PRINT MODAL

.report-company-print-modal .modal-content {
  background: transparent !important;
  border: 0 !important;
}

.report-company-print-modal .modal-header,
.report-company-print-modal .modal-body,
.report-company-print-modal .modal-footer {
  border: 0 !important;
}

.report-company-print-modal .modal-dialog {
  width: 1600px !important;
  --bs-modal-width: 1600px !important;
}


// GLOBAL STYLES FOR THE REPORT PRINT MODAL

.report-director-print-modal .modal-content {
  background: transparent !important;
  border: 0 !important;
}

.report-director-print-modal .modal-header,
.report-director-print-modal .modal-body,
.report-director-print-modal .modal-footer {
  border: 0 !important;
}

.report-director-print-modal .modal-dialog {
  width: 1600px !important;
  --bs-modal-width: 1600px !important;
}

.unselectable {
  cursor: default !important;
  user-select: none !important;
}

.blurred {
  @extend .unselectable;
  -webkit-filter: blur(3.5px) !important;
  filter: blur(3.5px) !important;
}

.blurred-strong {
  @extend .unselectable;
  -webkit-filter: blur(6px) !important;
  filter: blur(6px) !important;
}
