/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
// Replace above style with this SCSS file to enable dark mode styles

$font-size-root: 14px;
@import 'assets/sass/_variables';
@import 'bootstrap';
@import 'assets/sass/custom';
@import 'assets/sass/components/components';
@import 'assets/sass/vendors/vendors';
@import 'bootstrap-icons/font/bootstrap-icons.css';
@import "./node_modules/placeholder-loading/src/scss/placeholder-loading";

html,
body {
  height: 100%;
}
body {
  font-family: 'Roboto', sans-serif;
}
body {
  margin: 0;
}



.mat-mdc-tooltip-panel {
  z-index: 1060 !important;
}

.cdk-overlay-container {
  z-index: 1060 !important;
}
