$white: #ffffff;

$theme-colors: (
  'light': #f1f1f1,
  'dark': #000005,
  'primary': #6b54bf,
  'secondary': #7170d6,
  'info': #3d2dab,
  'accent1': #383456,
  'accent2': #5f6073,
  'accent3': #b7b0c0,
  'accent4': #e6e5e8,
  'success': #289c61,
  'warning': #deca2a,
  'danger': #e92916,
);
